import React, { useEffect, useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';
import axios from 'axios';
import '../style/CreatePlan.css'; // Import CSS file

const CreatePlan = ({ onSave, onClose, isEditing = false, existingPlan = null }) => {
  const [plan, setPlan] = useState({
    academy_name: '',
    sport_name: '',
    max_avail_arenas: 1,
    price_per_arena: '',
    GST: '',
    id: null // Initialize id as null
  });
  const [sportCenters, setSportCenters] = useState([]); // Unique academy names
  const [error, setError] = useState('');
  const [academySportsMap, setAcademySportsMap] = useState({}); // State to hold the academy-sports mapping
  const [selectedAcademy, setSelectedAcademy] = useState("");
  // State for weekend and weekday data
  const [weekendData, setWeekendData] = useState([{ startTime: '', endTime: '', price: '' }]);
  const [weekdayData, setWeekdayData] = useState([{ startTime: '', endTime: '', price: '' }]);

 
  // Handle changes in weekend data
  const handleWeekendChange = (index, field, value) => {
    const updatedWeekendData = [...weekendData];
    updatedWeekendData[index][field] = value;
    setWeekendData(updatedWeekendData);
  };

  // Handle changes in weekday data
  const handleWeekdayChange = (index, field, value) => {
    const updatedWeekdayData = [...weekdayData];
    updatedWeekdayData[index][field] = value;
    setWeekdayData(updatedWeekdayData);
  };

  // Handle adding a new row to weekend data
  const addWeekendRow = () => {
    setWeekendData([...weekendData, { startTime: '', endTime: '', price: '' }]);
  };
  // Populate form fields if editing
  useEffect(() => {
    if (isEditing && existingPlan) {
      console.log('Pre-populating form with existing plan:', existingPlan); // Debugging
      setPlan({
        academy_name: existingPlan.academy_name || '',
        sport_name: existingPlan.sport_name || '',
        max_avail_arenas: existingPlan.max_avail_arenas || 1,
        price_per_arena: existingPlan.price_per_arena || '',
        GST: existingPlan.GST || '',
        id: existingPlan.id || ''
      });
      setSelectedAcademy(existingPlan.academy_name || "");
    } else {
      // Reset form when not editing
      setPlan({
        academy_name: '',
        sport_name: '',
        max_avail_arenas: 1,
        price_per_arena: '',
        GST: '',
        id: null
      });
      setSelectedAcademy("");
    }
  }, [isEditing, existingPlan]);

  // Fetch academies data on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://admin.machaxi.com/summer-api/get-data', {
          headers: {
            'x-api-key': 'jNtunfkUwiAT4YXgQacG84SXO0ZRrzCxSnyUaFunqg' // Replace with your actual API key
          }
        }); // Use a dedicated endpoint

        // Directly access academies from the response data
        const academies = response.data.academies;

        // Check if academies is defined and is an array
        if (!Array.isArray(academies)) {
          throw new Error('Expected academies to be an array');
        }

        // Create a mapping of academy names to sports
        const academySportsMap = {};
        academies.forEach(item => {
          const { academy_name, sport_name } = item;
          if (!academySportsMap[academy_name]) {
            academySportsMap[academy_name] = [];
          }
          academySportsMap[academy_name].push(sport_name);
        });

        const centers = Object.keys(academySportsMap);
        setSportCenters(centers);
        setAcademySportsMap(academySportsMap);
      } catch (error) {
        console.error('Error fetching academies:', error);
        setError('Failed to fetch academies. Please try again.');
      }
    };

    fetchData();
  }, []);

  // Handle changes in the plan fields
  const handlePlanChange = (field, value) => {
    const updatedPlan = { ...plan, [field]: value };
    // Reset sport_name if academy_name changes
    if (field === 'academy_name') {
      updatedPlan.sport_name = '';
      setSelectedAcademy(value);
    }
    setPlan(updatedPlan);
  };
// Handle adding a new row to weekday data
const addWeekdayRow = () => {
  setWeekdayData([...weekdayData, { startTime: '', endTime: '', price: '' }]);
};

// Handle removing a row from weekend data
const removeWeekendRow = (index) => {
  if (weekendData.length > 1) {
    const updatedWeekendData = weekendData.filter((_, i) => i !== index);
    setWeekendData(updatedWeekendData);
  }
};

// Handle removing a row from weekday data
const removeWeekdayRow = (index) => {
  if (weekdayData.length > 1) {
    const updatedWeekdayData = weekdayData.filter((_, i) => i !== index);
    setWeekdayData(updatedWeekdayData);
  }
};
  // Handle form submission to add or edit plans
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate form fields
    if (!plan.academy_name || !plan.sport_name || !plan.max_avail_arenas || !plan.price_per_arena || !plan.GST) {
      setError('Please fill in all required fields for the plan.');
      return;
    }

    // Prepare formData with appropriate flags
    const formData = {
      id: isEditing ? plan.id : null,
      academy_name: plan.academy_name,
      sport_name: plan.sport_name,
      max_avail_arenas: parseInt(plan.max_avail_arenas, 10),
      price_per_arena: parseFloat(plan.price_per_arena),
      GST: parseFloat(plan.GST),
      isCreatePlan: isEditing ? 0 : 1,
      isCreateAddon: 0,
      isCreateDiscount: 0,
      isEditPlan: isEditing ? 1 : 0,
      isEditAddon: 0,
      isEditDiscount: 0,
      weekendData, // Include weekend data
      weekdayData // Include weekday data
    
    };

    try {
      const response = await axios.post('https://admin.machaxi.com/summer-api/admin-post-data', formData, {
        headers: {
          'x-api-key': 'jNtunfkUwiAT4YXgQacG84SXO0ZRrzCxSnyUaFunqg' // Replace with your actual API key
        }
      });
      console.log('Response:', response.data);
      setError(''); // Clear any existing errors
      onSave(response.data); // Trigger callback to refresh view or update state
      onClose(); // Close the modal or form after saving
    } catch (error) {
      console.error('Error saving plan:', error);
      if (error.response) {
        setError(error.response.data.message || 'Failed to save the plan.');
      } else if (error.request) {
        setError('No response from the server. Please check your network.');
      } else {
        setError('Error: ' + error.message);
      }
    }
  };
  const generateHourOptions = () => {
    const hours = [];
    for (let i = 1; i <= 24; i++) {
      const hour = i < 10 ? `0${i}:00` : `${i}:00`;
      hours.push(hour);
    }
    return hours;
  };
  // Handle key down for input navigation
  const handleKeyDown = (event) => {
    const focusableElements = Array.from(event.currentTarget.querySelectorAll('input, select, button'));
    const index = focusableElements.indexOf(document.activeElement);
    let nextIndex = index;

    if (event.key === 'Tab') {
      if (event.shiftKey) {
        nextIndex = index === 0 ? focusableElements.length - 1 : index - 1;
      } else {
        nextIndex = index === focusableElements.length - 1 ? 0 : index + 1;
      }
      focusableElements[nextIndex].focus();
      event.preventDefault(); // Prevent default tabbing behavior
    }
  };

  return (
    <div className="create-plan-container" onKeyDown={handleKeyDown}>
      <h2>{isEditing ? 'Edit Plan' : 'Create a New Plan'}</h2>

      {error && <p className="error-message">{error}</p>}

      <form onSubmit={handleSubmit}>
        {/* Academy Dropdown */}
        <div className="dropdown">
          <label htmlFor="academy_name">Select Sport Center:</label>
          {isEditing ? (
            <input
              type="text"
              id="academy_name"
              value={plan.academy_name}
              readOnly
              className="fixed-input"
            />
          ) : (
            <select
              id="academy_name"
              onChange={(e) => {
                const selectedAcademy = e.target.value;
                handlePlanChange('academy_name', selectedAcademy);
              }}
              value={plan.academy_name}
              required
            >
              <option value="">Select an Academy</option>
              {sportCenters.map(center => (
                <option key={center} value={center}>
                  {center}
                </option>
              ))}
            </select>
          )}
        </div>

        {/* Sport Type Field: Dropdown or Fixed Input */}
        <div className="dropdown">
          <label htmlFor="sport_name">Select Sport Type:</label>
          {isEditing ? (
            <input
              type="text"
              id="sport_name"
              value={plan.sport_name}
              readOnly
              className="fixed-input"
            />
          ) : (
            <select
              id="sport_name"
              value={plan.sport_name}
              onChange={(e) => handlePlanChange('sport_name', e.target.value)}
              required
            >
              <option value="">Select a Sport</option>
              {academySportsMap[selectedAcademy] && academySportsMap[selectedAcademy].map((sport, idx) => (
                <option key={idx} value={sport}>
                  {sport}
                </option>
              ))}
            </select>
          )}
        </div>

        {/* Sport Type Dropdown */}
       
        {/* Number of Courts */}
        {(plan.sport_name === 'Badminton' || plan.sport_name === 'TT') && (
          <div className="court-selection">
            <h3>Arena: Select Number of Courts</h3>
            <div className="court-counter">
              <button
                type="button"
                onClick={() => handlePlanChange('max_avail_arenas', Math.max(1, plan.max_avail_arenas - 1))}
                className="counter-btn"
                disabled={plan.max_avail_arenas <= 1}
              >
                <FaMinus />
              </button>
              <span className="court-count">{plan.max_avail_arenas}</span>
              <button
                type="button"
                onClick={() => handlePlanChange('max_avail_arenas', plan.max_avail_arenas + 1)}
                className="counter-btn"
              >
                <FaPlus />
              </button>
            </div>
          </div>
        )}

        {/* GST Input */}
        <div className="input-field">
          <label htmlFor="GST">GST:</label>
          <input
            type="text" // Keep it as text for flexibility
            id="GST"
            value={plan.GST}
            onChange={(e) => {
              const value = e.target.value;
              // Validate input: allow only numbers and one decimal point
              if (/^\d*\.?\d*$/.test(value) || value === '') {
                handlePlanChange('GST', value);
              }
            }}
            required
          />
        </div>

{/* Weekend Table */}
<div className="time-price-table weekend-table">
  <h3 className="table-heading">Weekend</h3>
  <table className="price-table">
    <thead>
      <tr>
        <th className="table-header">Start Time</th>
        <th className="table-header">End Time</th>
        <th className="table-header">Price</th>
        <th className="table-header">Actions</th>
      </tr>
    </thead>
    <tbody>
      {weekendData.map((row, index) => (
        <tr key={index} className="table-row">
          <td className="table-cell">
            <select
              className="time-select"
              value={row.startTime}
              onChange={(e) => handleWeekendChange(index, 'startTime', e.target.value)}
              required
            >
              <option value="">Select Start Time</option>
              {generateHourOptions().map((hour) => (
                <option key={hour} value={hour}>
                  {hour}
                </option>
              ))}
            </select>
          </td>
          <td className="table-cell">
            <select
              className="time-select"
              value={row.endTime}
              onChange={(e) => handleWeekendChange(index, 'endTime', e.target.value)}
              required
              disabled={!row.startTime}  // Disable if no start time is selected
            >
              <option value="">Select End Time</option>
              {generateHourOptions().filter(hour => {
                if (!row.startTime) return false; // Skip if no start time selected
                const startTime = parseInt(row.startTime.split(":")[0], 10);
                const endTime = parseInt(hour.split(":")[0], 10);
                return endTime > startTime; // Only show hours after the selected start time
              }).map((hour) => (
                <option key={hour} value={hour}>
                  {hour}
                </option>
              ))}
            </select>
          </td>
          <td className="table-cell">
            <input
              className="price-input"
              type="text"
              value={row.price}
              onChange={(e) => handleWeekendChange(index, 'price', e.target.value)}
              required
            />
          </td>
          <td className="table-cell actions-cell">
            <button
              className="action-btn minus-btn"
              type="button"
              onClick={() => setWeekendData(weekendData.filter((_, i) => i !== index))}
              disabled={weekendData.length === 1}
            >
              <FaMinus />
            </button>
            {index === weekendData.length - 1 && (
              <button
                className="action-btn plus-btn"
                type="button"
                onClick={() =>
                  setWeekendData([...weekendData, { startTime: '', endTime: '', price: '' }])
                }
              >
                <FaPlus />
              </button>
            )}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>

{/* Weekday Table */}
<div className="time-price-table weekday-table">
  <h3 className="table-heading">Weekday</h3>
  <table className="price-table">
    <thead>
      <tr>
        <th className="table-header">Start Time</th>
        <th className="table-header">End Time</th>
        <th className="table-header">Price</th>
        <th className="table-header">Actions</th>
      </tr>
    </thead>
    <tbody>
      {weekdayData.map((row, index) => (
        <tr key={index} className="table-row">
          <td className="table-cell">
            <select
              className="time-select"
              value={row.startTime}
              onChange={(e) => handleWeekdayChange(index, 'startTime', e.target.value)}
              required
            >
              <option value="">Select Start Time</option>
              {generateHourOptions().map((hour) => (
                <option key={hour} value={hour}>
                  {hour}
                </option>
              ))}
            </select>
          </td>
          <td className="table-cell">
            <select
              className="time-select"
              value={row.endTime}
              onChange={(e) => handleWeekdayChange(index, 'endTime', e.target.value)}
              required
              disabled={!row.startTime}  // Disable if no start time is selected
            >
              <option value="">Select End Time</option>
              {generateHourOptions().filter(hour => {
                if (!row.startTime) return false; // Skip if no start time selected
                const startTime = parseInt(row.startTime.split(":")[0], 10);
                const endTime = parseInt(hour.split(":")[0], 10);
                return endTime > startTime; // Only show hours after the selected start time
              }).map((hour) => (
                <option key={hour} value={hour}>
                  {hour}
                </option>
              ))}
            </select>
          </td>
          <td className="table-cell">
            <input
              className="price-input"
              type="text"
              value={row.price}
              onChange={(e) => handleWeekdayChange(index, 'price', e.target.value)}
              required
            />
          </td>
          <td className="table-cell actions-cell">
            <button
              className="action-btn minus-btn"
              type="button"
              onClick={() => setWeekdayData(weekdayData.filter((_, i) => i !== index))}
              disabled={weekdayData.length === 1}
            >
              <FaMinus />
            </button>
            {index === weekdayData.length - 1 && (
              <button
                className="action-btn plus-btn"
                type="button"
                onClick={() =>
                  setWeekdayData([...weekdayData, { startTime: '', endTime: '', price: '' }])
                }
              >
                <FaPlus />
              </button>
            )}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>



        <button type="submit" className="submit-button">{isEditing ? 'Update Plan' : 'Create Plan'}</button>
      </form>
    </div>
  );
};

export default CreatePlan;