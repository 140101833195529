import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import AddPage from '../components/CreateAddons'; // Ensure the import path is correct
import '../style/ViewAddons.css';

// Required for screen readers
Modal.setAppElement('#root');

const ViewAddons = () => {
  const [showAddPage, setShowAddPage] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentAddon, setCurrentAddon] = useState(null);
  const [addons, setAddons] = useState([]);
  const [error, setError] = useState('');

  // Function to fetch data from the API
  const fetchData = async () => {
    try {
      const response = await axios.get('https://admin.machaxi.com/summer-api/get-data', {
        headers: {
          'x-api-key': 'jNtunfkUwiAT4YXgQacG84SXO0ZRrzCxSnyUaFunqg' // Replace with your actual API key
        }
      });
      const fetchedAddons = response.data.allAddons.map((addon) => ({
        id: addon.id,
        name: addon.addon_name,
        description: addon.description,
        unitPrice: addon.unit_price,
        gst: addon.GST,
      }));
      setAddons(fetchedAddons);
    } catch (error) {
      console.error('Error fetching addons:', error);
      setError('Failed to fetch addons. Please try again.');
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchData();
  }, []);

  const handleAddMore = () => {
    setCurrentAddon(null);
    setIsEditing(false);
    setShowAddPage(true);
  };

  const handleEditAddon = (addon) => {
    setCurrentAddon(addon);
    setIsEditing(true);
    setShowAddPage(true);
  };

  const handleClose = () => {
    setShowAddPage(false);
  };

  const handleDeleteAddon = async (id) => {
    const formData = {
      id: id,
      isisDisablePlan : 0, 
      isDisableAddon : 1, 
      isDisableDiscount : 0
    }
    try {
      await axios.put(`https://admin.machaxi.com/summer-api/disable-data` , formData,{
        headers: {
          'x-api-key': 'jNtunfkUwiAT4YXgQacG84SXO0ZRrzCxSnyUaFunqg' // Replace with your actual API key
        }
      });
      setAddons(addons.filter((addon) => addon.id !== id)); 
    } catch (error) {
      console.error('Error deleting addon:', error);
      setError('Failed to delete the addon. Please try again.');
    }
  };

  const handleSaveAddon = () => {
    fetchData(); // Fetch the latest addons list after saving
  };

  return (
    <div className="view-addons-container">
      <h2>Add-ons</h2>
      {error && <p className="error-message">{error}</p>}
      <div className="button-container">
        <button className="add-more-button" onClick={handleAddMore}>
          Create Add-ons
        </button>
      </div>

      <table className="addons-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Unit Price</th>
            <th>GST (%)</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {addons.map((addon) => (
            <tr key={addon.id}>
              <td>{addon.name}</td>
              <td>{addon.description}</td>
              <td>{addon.unitPrice}</td>
              <td>{addon.gst}</td>
              <td>
                <button className="edit-button" onClick={() => handleEditAddon(addon)}>
                  Edit
                </button>
                <button className="delete-button" onClick={() => handleDeleteAddon(addon.id)}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        isOpen={showAddPage}
        onRequestClose={handleClose}
        contentLabel="Add/Edit Addon Modal"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <div className="modal-header">
          <AddPage 
            onClose={handleClose} 
            onSave={handleSaveAddon} 
            addon={currentAddon} 
            isEditing={isEditing} 
          />
          <button onClick={handleClose} className="close-modal-button">
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ViewAddons;
